import React, { useState } from "react"

import Layout from "../components/layout"

import styles from "./contactez-nous.module.scss"
import Seo from "../components/seo"

const ContactezNous = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    console.log(firstName, lastName, email, message)
    setFirstName("")
    setLastName("")
    setEmail("")
    setMessage("")
  }
  return (
    <Layout>
      <Seo
        title="contactez Wistibike, le spécialiste des vélos électriques à Annemasse"
        description="formulaire pour contacter Wistibike"
      />
      <main className={styles.mainContainer}>
        <h1>Contactez-nous</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="first-name">Prénom</label>
          <input
            type="text"
            value={firstName}
            required
            onChange={e => setFirstName(e.target.value)}
          />
          <label htmlFor="last-name">Nom</label>
          <input
            type="text"
            value={lastName}
            required
            onChange={e => setLastName(e.target.value)}
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
          />
          <label htmlFor="message">Message</label>
          <textarea
            rows="6"
            value={message}
            required
            onChange={e => setMessage(e.target.value)}
          />
          <input type="submit" value="Envoyer" />
        </form>
      </main>
    </Layout>
  )
}

export default ContactezNous
