import React from "react"
import { Link } from "gatsby"

import footerStyles from "./footer.module.scss"

const Footer = () => {
  return (
    <div className={footerStyles.footer}>
      <nav className={footerStyles.container}>
        <ul className={footerStyles.redTape}>
          <li>
            <Link to="/mentions-legales">Mentions légales</Link>
          </li>
          <li>
            <Link to="/cgu-cgv">CGU / CGV</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Footer
