import React, { useContext, useRef, useEffect, useState } from "react"
import CartContext from "../contexts/CartContext"
import { Link } from "gatsby"

import styles from "./header.module.scss"

const Header = () => {
  const { cart } = useContext(CartContext)
  const [isMenuOut, setIsMenuOut] = useState(false)
  const checkBoxRef = useRef()
  const menuToggleRef = useRef()

  const handleClick = e => {
    if (e.target === checkBoxRef.current) {
      return
    }
    if (menuToggleRef.current.contains(e.target)) {
      return
    }
    setIsMenuOut(false)
  }

  const handleKeyDown = e => {
    // check keys if you want
    if (e.keyCode === 13) {
      setIsMenuOut(!isMenuOut)
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleClick)

    // cleanup this component
    return () => {
      window.removeEventListener("click", handleClick)
    }
  }, [])

  return (
    <div className={styles.header}>
      <div className={styles.brand}>
        <Link to="/ville">Wistibike.com</Link>
      </div>
      <Link to="/panier" className={styles.basket}>
        <div className={styles.iconCart}>
          <p className={cart.length ? styles.itemNumber : null}>
            {cart.length ? cart.length.toString() : null}
          </p>
          <div className={styles.cartLine1}></div>
          <div className={styles.cartLine2}></div>
          <div className={styles.cartLine3}></div>
          <div className={styles.cartWheel}></div>
        </div>
      </Link>
      <div
        tabIndex="0"
        aria-label="MenuOut"
        role="button"
        onKeyDown={() => handleKeyDown()}
        onClick={() => setIsMenuOut(!isMenuOut)}
        ref={checkBoxRef}
        className={styles.box}
      ></div>
      <div className={styles.hamburger}>
        <span
          className={
            isMenuOut ? `${styles.span1} ${styles.activeSpan1}` : styles.span1
          }
        ></span>
        <span
          className={
            isMenuOut ? `${styles.span2} ${styles.activeSpan2}` : styles.span2
          }
        ></span>
        <span
          className={
            isMenuOut ? `${styles.span3} ${styles.activeSpan3}` : styles.span3
          }
        ></span>
      </div>
      <nav
        ref={menuToggleRef}
        className={
          isMenuOut
            ? `${styles.menuToggle} ${styles.menuToggleOut}`
            : styles.menuToggle
        }
        role="navigation"
      >
        <ul className={styles.menuBikes}>
          <li>
            <Link activeClassName={styles.activeNavItem} to="/ville">
              Vélos Ville
            </Link>
          </li>
          <li>
            <Link activeClassName={styles.activeNavItem} to="/speedBike">
              Speed Bikes
            </Link>
          </li>
          <li>
            <Link activeClassName={styles.activeNavItem} to="/cargo">
              Vélos Cargo
            </Link>
          </li>
          <li>
            <Link activeClassName={styles.activeNavItem} to="/vtt">
              VTT
            </Link>
          </li>
          <li>
            <Link activeClassName={styles.activeNavItem} to="/route">
              Vélos Route
            </Link>
          </li>
          <li>
            <Link activeClassName={styles.activeNavItem} to="/accessoires">
              Accessoires
            </Link>
          </li>
        </ul>
        <ul className={styles.menuContact}>
          <li>
            <Link activeClassName={styles.activeNavItem} to="/qui-sommes-nous">
              Qui sommes-nous ?
            </Link>
          </li>
          <li>
            <Link activeClassName={styles.activeNavItem} to="/sav">
              Service Après-vente
            </Link>
          </li>
          <li>
            <Link activeClassName={styles.activeNavItem} to="/contactez-nous">
              Contactez-nous
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Header
